<!-- 区校一体化解决方案 -->
<template>
  <div class="kfszhxy_div">
		<div class="top-img">
      <img src="static/lvya/kfszhxy/banners.png" alt="">
    </div>
		<div class="module module-01">
			<div class="w1200">
        <div class="tip-text">
          <p class="title">系统框架</p>
          <p class="line"></p>
          <p class="text">以学生成长为核心，通过核心技术构建的过程管理引擎。各种应用，随需而生，
            可以把学校任何一个教育过程或者实际场景，及时形成一套系统。同时，系统具有完全开放的动态接口，
            可以随时接入软硬件环境来支撑学校的这些教育过程和教育场景的发生，让学校所有软硬件都体现出育人功能。</p>
        </div>
        <img class="img wow animate__fadeInUp" src="static/lvya/kfszhxy/03.png" alt="">
      </div>
		</div>
    <div class="module module-03">
      <div class="w1200">
        <div class="tip-text">
          <p class="title">典型功能</p>
          <p class="line"></p>
        </div>
        <div class="list_div wow animate__slideInLeft">
          <div class="left">
            <img src="static/lvya/kfszhxy/04.png" alt="">
          </div>
          <div class="right">
            <p class="p1">统一基础平台</p>
            <p class="p2">实现多平台融合、统一数据中心、统一展示平台、单点登录、一站式服务。</p>
          </div>
        </div>
        <div class="list_div2 wow animate__slideInRight">
          <div class="right">
            <p class="p1">基础支撑平台</p>
            <p class="p2">通过核心技术构建教育场景过程管理引擎，各种应用随需而生，把任何一个教育过程或场景，根据学校实际需求快速生成一套应用系统。</p>
          </div>
          <div class="left">
            <img src="static/lvya/kfszhxy/05.png" alt="">
          </div>
        </div>
        <div class="list_div wow animate__slideInLeft">
          <div class="left">
            <img src="static/lvya/kfszhxy/06.png" alt="">
          </div>
          <div class="right">
            <p class="p1">基础应用</p>
            <p class="p2">围绕着学校人财物的管理，搭建各类应用系统。系统之间能够动态关联，支撑学校发展目标展的开展和建设。数据沉淀能够为学校管理决策提供数据支撑。</p>
          </div>
        </div>
        <div class="list_div2 wow animate__slideInRight">
          <div class="right">
            <p class="p1">教学评一体化</p>
            <p class="p2"> 基于一体化教学管理进行整体规划，围绕学校课程设计与学生能力培养目标，对学校教、学、管、评、考全流程从顶层设计角度进行业务融合,形成整套一体化教学管理与服务系统。</p>
          </div>
          <div class="left">
            <img src="static/lvya/kfszhxy/07.png" alt="">
          </div>
        </div>
        <div class="list_div wow animate__slideInLeft">
          <div class="left">
            <img src="static/lvya/kfszhxy/08.png" alt="">
          </div>
          <div class="right">
            <p class="p1">大数据驾驶舱</p>
            <p class="p2">数据驾驶舱采用可视化交互式软件操作方式，区别于传统的表格式，文字式软件操作方式，让领导用一种简单、快捷的方式，来运用信息化。</p>
          </div>
        </div>
      </div>
    </div>
		<div class="module module-02">
			<div class="w1200">
        <div class="tip-text">
          <p class="title">方案特色</p>
          <p class="line"></p>
        </div>
				<div class="list-flex wow animate__fadeInLeft">
					<div class="list">
						<img src="static/lvya/kfszhxy/10.png" alt="">
						<p class="p1">开放式智慧校园架构</p>
						<p class="p2">开放式智慧校园的灵活架构，支撑需求变化及未来扩展。</p>
					</div>
					<div class="list">
						<img src="static/lvya/kfszhxy/09.png" alt="">
						<p class="p1">统一集成管理</p>
						<p class="p2">实现各类业务系统的统一集成与管理，实现数据的集成和整合。</p>
					</div>
					<div class="list">
						<img src="static/lvya/kfszhxy/11.png" alt="">
						<p class="p1">各种应用随需而生</p>
						<p class="p2">面向教育场景的工作流引擎使得学校各种应用”随需而生“，快速契合学校的使用流程和教师的使用习惯。</p>
					</div>
					<div class="list">
						<img src="static/lvya/kfszhxy/12.png" alt="">
						<p class="p1">数据可视化诊断</p>
						<p class="p2">所有信息化数据的汇聚将形成学校大数据驾驶舱，分析形成学生画像、教师画像和学校发展成果，为学校诊断改进提供科学依据。</p>
					</div>
				</div>
      </div>
		</div>
		<div class="module module-04">
			<div class="w1200">
        <div class="tip-text">
          <p class="title">案例介绍</p>
          <p class="line"></p>
        </div>
				<div class="top wow animate__slideInLeft">
					<div class="left">
						<img src="static/lvya/kfszhxy/01.png" alt="">
					</div>
					<div class="right">
						<div class="right-left">
							<p class="p1">江苏省天一中学</p>
							<p class="p2">高标准规划建设打造头部学校样板</p>
							<p class="p3">作为国家级示范性高中，站在学校整体高度，采用“顶层设计”的方法，为学校搭建智慧校园平台，从学生、教师、学校三个实践维度践行 “适合的教育”理念，形成个性化教育的实施范例。重点覆盖教学、教研、管理等日常工作环节，融合新技术、新设备将互联网、大数据、人工智能、区块链等现代技术广泛应用于学校管理和师生互动等方面，促进信息技术与教育教学的深度融合，打造头部学校样板。</p>
						</div>
						<div class="right-right">
							<p>客户案例</p>
						</div>
					</div>
				</div>
				<div class="bottom wow animate__slideInRight">
					<div class="right">
						<div class="right-right">
							<p>客户案例</p>
						</div>
						<div class="right-left">
							<p class="p1">天津大学</p>
							<p class="p2">信息化助力学校“高标准规划、高起点建设</p>
							<p class="p3">响应国家加快“双一流”高校建设要求，与校方共同围绕天津大学的特色“三全育人”“五育并举”人才培养综合改革方案的落地，共筑卓越人才培养目标——“四梁八柱”的牢靠地基。用教育信息化技术支持学校教育高质量内涵式发展，构建中外名校“1+N”一流学科共建的模式。</p>
						</div>
					</div>
					<div class="left">
						<img src="static/lvya/kfszhxy/02.png" alt="">
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { WOW } from 'wowjs'
export default {
  props: {},
  components: {},
  data() {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
  mounted() {
		this.$nextTick(() => {
      let wow = new WOW({
				boxClass: 'wow',
				animateClass: 'animate__animated',
				offset: '0',
				mobile: true,
				live: true
      })
      wow.init()
    })
	}
}
</script>

<style lang="less" scoped>
/* @import url(); 引入css类 */
.kfszhxy_div{
	width: 100%;
  min-width: 1200px;
  .top-img{
    line-height: 0;
    img{
      width: 100%;
      min-width: 1200px;
    }
  }
	.module{
    width: 100%;
    min-height: 200px;
    .w1200{
      width: 1200px;
      min-height: 100px;
      margin: 0 auto;
      padding: 50px 0 20px 0;
      box-sizing: border-box;
      .tip-text{
        text-align: center;
        .title{
          color: #222222;
          font-size: 30px;
          font-weight: bold;
        }
        .line{
          width: 63px;
          height: 5px;
          background: #19CA81;
          margin: 15px auto;
        }
        .text{
          font-size: 16px;
          color: #696969;
          line-height: 28px;
        }
        .w1090{
          width: 1090px;
          margin: 0 auto;
        }
      }
      .tip2-text{
        margin-top: 15px;
        .title{
          width: 1060px;
          margin: 0 auto;
          display: flex;
          align-content: center;
          img{
            width: 21px;
            height: 21px;
            margin-right: 10px;
            margin-top: 2px;
          }
          p{
            font-size: 18px;
            color: #222222;
            font-weight: bold;
          }
        }
        .text2{
          font-size: 16px;
          color: #696969;
          margin-top: 15px;
          line-height: 28px;
        }
        .w1060{
          width: 1060px;
          margin: 0 auto;
          margin-top: 15px;
        }
      }
      .tip2-text:first-child{
        margin-top: 60px;
      }
      .img{
        width: 100%;
        margin-top: 60px;
      }
      .img2{
        width: 100%;
        margin-top: 10px;
      }
    }
  }
	.module-02{
		width: 100%;
    min-height: 200px;
		.list-flex{
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			margin-top: 40px;
			margin-bottom: 40px;
			.list{
				width: 23%;
				min-height: 100px;
				border: 1px solid #ddd;
				padding: 30px 20px;
				box-sizing: border-box;
				text-align: center;
				background: #fff;
				.p1{
					color: #333;
					font-size: 16px;
					font-weight: 600;
					margin-top: 15px;
				}
				.p2{
					color: #666;
					font-size: 15px;
					line-height: 26px;
					margin-top: 12px;
				}
			}
		}
	}
  .module-03{
    min-height: 180px;
		background: #fbfbfb;
    padding-bottom: 50px;
    .list_div{
      min-height: 270px;
      display: flex;
      border: 1px dashed #ddd;
      border-radius: 5px;
      margin-top: 50px;
      justify-content: space-between;
      background: #fff;
      .left{
        width: 500px;
        line-height: 0;
        img{
          max-width: 100%;
        }
      }
      .right{
        flex: 1;
        padding: 50px;
        box-sizing: border-box;
        .p1{
          font-size: 26px;
          font-weight: 600;
          color: #333;
        }
        .p2{
          font-size: 16px;
          color: #444;
          margin-top: 30px;
          line-height: 30px;
        }
      }
    }
    .list_div2{
      min-height: 270px;
      display: flex;
      border: 1px dashed #ddd;
      border-radius: 5px;
      margin-top: 50px;
      justify-content: space-between;
      background: #fff;
      .left{
        width: 500px;
        line-height: 0;
        img{
          max-width: 100%;
        }
      }
      .right{
        flex: 1;
        padding: 50px;
        box-sizing: border-box;
        .p1{
          font-size: 26px;
          font-weight: 600;
          color: #333;
        }
        .p2{
          font-size: 16px;
          color: #444;
          margin-top: 30px;
          line-height: 30px;
        }
      }
    }
  }
	.module-04{
		min-height: 180px;
		background: #fbfbfb;
		.top{
			width: 100%;
			display: flex;
			background: #fff;
			align-items: center;
			margin-top: 50px;
			.left{}
			.right{
				display: flex;
				align-items: center;
				padding: 20px 20px;
				box-sizing: border-box;
				.right-left{
					border-right: 1px dashed #ccc;
					padding-right: 20px;
					.p1{
						font-size: 20px;
						color: #333;
						font-weight: 600;
					}
					.p2{
						color: #333;
						font-size: 16px;
						font-weight: 600;
						margin-top: 15px;
					}
					.p3{
						color: #666;
						font-size: 14px;
						line-height: 30px;
						margin-top: 15px;
					}
				}
				.right-right{
					padding-left: 20px;
				}
			}
		}
		.bottom{
			width: 100%;
			display: flex;
			background: #fff;
			align-items: center;
			margin-top: 50px;
			margin-bottom: 60px;
			.left{}
			.right{
				display: flex;
				align-items: center;
				padding: 20px 20px;
				box-sizing: border-box;
				.right-left{
					border-left: 1px dashed #ccc;
					padding-left: 20px;
					.p1{
						font-size: 20px;
						color: #333;
						font-weight: 600;
					}
					.p2{
						color: #333;
						font-size: 16px;
						font-weight: 600;
						margin-top: 15px;
					}
					.p3{
						color: #666;
						font-size: 14px;
						line-height: 30px;
						margin-top: 15px;
					}
				}
				.right-right{
					padding-right: 20px;
				}
			}
		}
	}
}
</style>
